<template>
  <div class="content-wrapper">
    <div class="worktime main-content">
      <div class="page-header">
        <h1>Financial Year Setting</h1>
      </div>
      <div class="form-wrapper">
        <div class="action-button-wrapper">
          <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
          <button v-if="!disabled" class="btn btn-primary saveButton" @click="updateSettings">Save</button>
        </div>

        <h2 class="card-title">Financial Year</h2>
        <div class="card info-card">
          <div class="message">You can setup the month and day of the financial year.</div>
          <el-form ref="worktime-form" :disabled="disabled" :model="form" label-width="200px">
            <div class="row">
              <el-date-picker
                v-model="form.financialYear"
                type="date"
                format="MM-dd"
                placeholder="Select month and date">
              </el-date-picker>
            </div>
          </el-form>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'Worktime',
  data(){
    return {
      disabled: true,
      form: {
        financialYear: '',
      },
    }
  },
  mounted(){
    this.loadFinancialSettings();
  },
  methods:{
    startEdit(){
      this.disabled = false;
    },
    async loadFinancialSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        let financialYear = await Common.loadConfig(this.apiUrl, 'financial_year', loginInfo);
        financialYear = financialYear['config_value'];

        const financialYearRegax = /([0-9]+)-([0-9]+)/;
        let financialYearMonth = financialYearRegax.exec(financialYear)[1];
        let financialYearDay = financialYearRegax.exec(financialYear)[2];

        let blankDate = dayjs();
        blankDate = blankDate.set('month', (financialYearMonth - 1));
        blankDate = blankDate.set('date', financialYearDay);
        
        this.form.financialYear = blankDate;
        this.disabled = true;
      }catch(err){
        console.log(err);
      }
    },
    async updateSettings(){
      try{
        const loginInfo = Common.getLoginInfo();
        const financialYear = dayjs(this.form.financialYear).format('MM-DD');
        await Common.updateConfig(this.apiUrl, 'financial_year', financialYear, loginInfo);
        this.disabled = true;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">

.info-card{
  padding: 30px;

  .message{
    position: relative;
    right: 13px;
    margin-bottom: 20px;
  }
}

.card-title{
  margin-bottom: 30px;
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
